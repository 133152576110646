.archive {
    z-index: 2;
    padding: 60px 0;
}

.archive .archive__content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 48px;
    margin: 0 -15px;
}

.archive .archive__content .archive__sidebar {
    width: 25%;
    padding: 0 15px;
}

.archive .archive__content .archive__sidebar .archive__sidebar-searchbar-wrapper {
    margin-bottom: 24px;
}

.archive .archive__content .archive__sidebar .archive__sidebar-searchbar-wrapper .searchbar {
   margin: 16px 0;
}

.archive  h1 {
    font-size: 22px;
    color: var(--white);
    margin-bottom: 24px;
}

.archive .title-tag {
    display: table;
    font-size: 12.5px;
    letter-spacing: 1.2px;
    padding: 2px 6px;
    border-radius: 16px;
    font-weight: 500;
    color: var(--white);
    background-color: var(--secondary);
    margin-bottom: 8px;
}


.archive .archive__content .archive__list-wrapper {
    position: relative;
    width: 75%;
    padding: 0 15px;
    min-height: 60vh;
}

.archive .archive__content .archive__list-wrapper .loader {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    transform: translateY(-50%);
}

.archive .archive__content .archive__list-wrapper .load-more span {
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--secondary);
    border-radius: 50%;
    margin: 10px auto;
    animation: loaderBounce .8s ease infinite;
}


.archive.tv-shows .title-tag {
    background-color: var(--tertiary);
}

.archive.tv-shows .archive__content .archive__list-wrapper .load-more span {
    background-color: var(--tertiary);
}

.archive.tv-shows .archive__content .archive__list-wrapper .loader span {
    background-color: var(--tertiary);
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .archive .archive__content .archive__sidebar {
        width: 30%;
    }
    .archive .archive__content .archive__list-wrapper {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
    .archive {
        padding: 40px 0;
    }
    .archive .archive__content .archive__sidebar {
        width: 100%;
        margin-bottom: 32px;
    }
    .archive .archive__content .archive__list-wrapper {
        width: 100%;
    }
}