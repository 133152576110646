.archive-filter {
    background-color: var(--dark-primary);
    border-radius: 16px;
}

.archive-filter__head {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    margin-bottom: 8px;
}

.archive-filter__body {
    padding: 0 22px 16px;
}

.archive-filter h4 {
    margin: 0 0 16px;
}

.archive-filter .archive-filter__body .archive-filter__form .archive-filter__form-section {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--layer-color);
}

.archive-filter .archive-filter__body .archive-filter__form .archive-filter__form-section:nth-last-child(2) {
    border-bottom: none;
    margin-bottom: 0;
}

.archive-filter__form .archive-filter__form-section .form-section__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.archive-filter__form .archive-filter__form-section .form-section__head h4 {
    margin: 0;
}

.archive-filter__form .archive-filter__form-section .form-section__body {
   max-height: 0;
   overflow: hidden;
   transition: max-height .4s ease;
}

.archive-filter__form .archive-filter__form-section.open-accordion .form-section__body {
    max-height: 600px;
}

.archive-filter__form .archive-filter__form-section .genre-tag {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
}

.archive-filter__form .archive-filter__form-section .genre-tag label{
   font-size: 14px;
   display: inline-block;
   cursor: pointer;
   padding: 2px 8px;
   border: 2px solid var(--layer-color);
   border-radius: 16px;
   transition: background-color .2s ease;
}

.archive-filter__form .archive-filter__form-section .genre-tag input{
    position: absolute;
    opacity: 0;
}

.archive-filter__form .archive-filter__form-section .genre-tag input:checked ~ label{
   background-color: var(--layer-color);
}

.archive-filter__form .archive-filter__form-section .rating-selector input {
    display: block;
    width: 100%;
    max-width: 150px;
}

.archive-filter__form .archive-filter__form-section .rating-selector .rating-selector__rating-displayer {
    margin-top: 16px;
    color: var(--secondary);
}

.archive-filter__form .archive-filter__form-section .rating-selector .rating-selector__rating-displayer.tv-shows {
    color: var(--tertiary);
}

.archive-filter__form .archive-filter__form-section .rating-selector .rating-selector__rating-displayer svg {
    width: 22px;
    height: 22px;
    margin: 0 4px;
}

.archive-filter__form .archive-filter__form-section .date-picker div {
    display: flex;
    align-items: center;
}

.archive-filter__form .archive-filter__form-section .date-picker div svg {
    margin-right: 10px;
}

.archive-filter__form .archive-filter__form-section .date-picker div:first-of-type  {
    margin-bottom: 8px;
}

.archive-filter__form button {
    display: block;
    width: 100%;
}

.archive-filter__body-close {
    display: none;
}

@media screen and (min-width: 640px) and (max-width: 1024px) { 
    .archive-filter {
        max-width: 50%;
    } 
}

@media screen and (max-width: 1024px) {
    .archive-filter__head {
        cursor: pointer;
        padding: 10px 16px;
        margin-bottom: 0;
    } 

    .archive-filter__body {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 24px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        z-index: 99;
        background-color: var(--dark-primary-layer);
        transform: translateY(100%);
        transition: transform .4s;
    } 

    .archive-filter.open .archive-filter__body {
        transform: translateY(0%);
    } 

    .archive-filter__body-close {
        position: absolute;
        z-index: 99;
        top: 16px;
        right: 24px;
        display: block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        background-color: var(--layer-color);
        color: var(--white);
    } 

    .archive-filter__body-close svg {
        vertical-align: middle;
        width: 18px;
        height: 18px;
    }
}