@import url('https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap');
@import './app-theme/inputs.css';
@import './app-theme/slider.css';

:root {
	--primary: #070d2d;
	--dark-primary: #05081a;
	--dark-primary-layer: rgba(5,8,26, 0.95);
	--secondary: #FF1053;
	--tertiary: #7A28CB;
	--cta: #449DD1;
	--cta-hover: #257db1;
	--black: #000000;
	--light-black: #080808;
	--white: #ffffff;
	--layer-color: rgba(255, 255, 255, 0.1);
	--gray: #b2b2b2;
	--search-bar: rgba(1,2,10, 0.6);

}

body {
	margin: 0;
	padding: 0;
	background-color: var(--primary);
	color: var(--white);
	font-family: 'Work Sans', sans-serif;
}

html.no-scroll {
	overflow: hidden;
}

* {
	box-sizing: border-box;
}

h1, h2, h3, h4 {
	font-weight: 500;
	letter-spacing: 1.2px;
	line-height: 1.3;
	margin: 0;
}

h1 {
	font-size: 24px;
	text-transform: uppercase;
}

h2 {
	font-size: 18px;
	text-transform: uppercase;
}

h3 {
	font-size: 14px;
	line-height: 1.5;
	text-transform: uppercase;
}

h4 {
	font-size: 12px;
	text-transform: uppercase;
}

p  {
	margin-top: 0;
}

a {
	color: var(--cta);
	text-decoration: none;
}

.logo {
	position: relative;
	z-index: 91;
}

.logo img {
	width: 155px;
}

.main-container {
	width: 100%;
	padding: 0 24px;
	max-width: 1280px;
	margin: 0 auto;
}

.loader {
	margin: auto;
	text-align: center;
}

.off-canvas-wrapper {
	transition: transform .4s ease;
	min-height: 100vh;
}

.off-canvas-wrapper.nav-displayed {
	transform: translateX(-21vw);
}

.off-canvas-wrapper .off-canvas-wrapper__backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 90;
	background-color: var(--dark-primary);
	opacity: 0;
	visibility: visible;
	transform: translateY(-100%);
	transition: opacity .4s ease, visibility 0s .4s;
}

.off-canvas-wrapper.nav-displayed .off-canvas-wrapper__backdrop {
	visibility: visible;
	transform: translateY(0%);
	opacity: 0.8;
	transition: opacity .4s ease;
}

@keyframes loaderBounce {
	0% {
		transform: translateY(0);
	}
	50% {
		transform: translateY(10px);
	}
	100% {
		transform: translateY(0);
	}
}

.loader span {
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: var(--secondary);
	margin: 0 5px;
	animation: loaderBounce .8s ease-in-out infinite;
}

.loader span:nth-child(2) {
	animation-delay: .1s;
}

.loader span:nth-child(3) {
	animation-delay: .2s;
}

button {
	color: var(--white);
	background-color: var(--cta);
	padding: 6px 16px;
	border-radius: 6px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-align: center;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
	transition: background-color .25s ease;
}

button:hover {
	background-color: var(--cta-hover);
}

.film-tv__switch {
    position: relative;
    z-index: 2;
    display: block;
	width: 120px;
    margin-bottom: 16px;
    border: 1.5px solid var(--secondary);
    border-radius: 16px;
    transition: border-color .3s;
}

.film-tv__switch:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1.5px;
    left: -1.5px;
    height: calc(100% + 3px);
    transition: all .3s;
    background-color: var(--secondary);
    width: 46.5px;
    border-radius: 16px;
}

.film-tv__switch.shows {
    border-color: var(--tertiary);
}

.film-tv__switch.shows:after {
    left: calc(100% - 70.5px);
    background-color: var(--tertiary);
    width: 72.5px;
}

.film-tv__switch span {
    display: inline-block;
    font-size: 12.5px;
    padding: 4px 8px;
    cursor: pointer;
}

.film-tv__switch span:first-child {
    max-width: 46px;
}

.film-tv__switch span:last-child {
    max-width: 72px;
}

.searchbar {
	display: flex;
	width: 100%;
}

.searchbar button {
	padding-left: 8px 12.5px;
	background-color: var(--search-bar);
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	font-size: 16px;
	line-height: 16px;
}

.searchbar input {
	flex: 2;
	padding: 8px 0 8px 12.5px;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	border: none;
	outline: none;
	background-color: var(--search-bar);
	color: var(--white);
	font-size: 14px;
}

@media screen and (min-width: 1328px) {
	.main-container {
		padding: 0;
	}
}

@media screen and (max-width: 640px) {
	.off-canvas-wrapper.nav-displayed {
		transform: translateX(-100vw);
	}
	.search-bar {
		max-width: none;
	}
}

@media screen and (min-width: 641px) and (max-width: 1023px ) {
	.off-canvas-wrapper.nav-displayed {
		transform: translateX(-40vw);
	}
	.search-bar {
		max-width: 50%;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px ) {
	.off-canvas-wrapper.nav-displayed {
		transform: translateX(-30vw);
	}
}