.actor-card {
    position: relative;
    z-index: 3;
    border-radius: 8px;
    overflow: hidden;
}

.actor-card:after {
    content: '';
    display: block;
    padding-bottom: 150%;
}

.actor-card:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 101%;
    width: 101%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(7,13,45,0.75) 70%);
    transition: opacity .4s ease;
}

.actor-card .actor-card__pic {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.actor-card .actor-card__content {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 0 8px;
}

.actor-card .actor-card__content .actor-card__name {
    font-size: 12px;
    margin-bottom: 4px;
    text-align: center;
}

.actor-card .actor-card__content .actor-card__character {
    font-size: 10px;
    color: var(--gray);
    text-align: center;
}