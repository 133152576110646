.main-header {
    position: relative;
    z-index: 99;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 21vw;
    z-index: 90;
    padding-top: 100px;
    background-color: var(--dark-primary);
    transform: translateX(100%);
}

.close {
    position: absolute;
    top: 14px;
    right: 24px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.close svg{
    height: 30px;
    width: 30px;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav ul.menu > li {
    padding: 0 24px;
    margin-bottom: 28px;
}

nav ul li > span:hover {
    opacity: 0.75;
}

nav ul.menu > li > a,
nav ul.menu > li > span {
    font-size: 16px;
    color: var(--white);
    letter-spacing: 1.2px;
}

nav ul.menu > li > a {
    display: table;
    position: relative;
    margin-left: 16px;
    padding-bottom: 4px;
}

nav ul.menu > li > span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--layer-color);
    padding: 10px 16px;
    border-radius: 8px;
}

nav ul li .submenu {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-in-out;
}

nav ul li .submenu li {
    padding-bottom: 8px;
}

nav ul li .submenu li:first-child {
    padding-top: 16px;
}

nav ul li .submenu li:last-child {
    border-bottom: 1px solid var(--layer-color);
}

nav ul li .submenu li a {
    position: relative;
    display: table;
    font-size: 14px;
    letter-spacing: 1.2px;
    margin: 0 0 4px 16px;
    padding-bottom: 4px;
    color: var(--white);
}

nav ul.menu > li > a:hover,
nav ul > li > .submenu > li > a:hover {
    opacity: 0.75;
}


.active-link {
    opacity: 0.5;
}


nav ul li .submenu.active {
    max-height: 600px;
}

.hamburger {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.hamburger svg {
    width: 30px;
    height: 30px;
}

/* Responsive */

@media screen and (max-width: 640px) {
    nav {
        width: 100vw;
    }
    .close {
        right: 0;
        left: 24px;
        transform: scaleX(-1);
    }
}

@media screen and (min-width: 641px) and (max-width: 1023px ) {
	nav {
        width: 40vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px ) {
	nav {
        width: 30vw;
    }
}