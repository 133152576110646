.archive-single {
    position: relative;
    z-index: 2;
    padding: 60px 0;
}

.archive-single-wrapper {
    max-width: 1000px;
    margin: auto;
}

.archive-single__bg {
    position: absolute;
    z-index: -1;
    top: -75.43px;
    left: 0;
    width: 100%;
    height: 450px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.archive-single__bg:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.7318277652858018) 0%, rgba(7,13,45,1) 100%);
}

.archive-single__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.archive-single__row:not(:last-child) { 
    margin-bottom: 32px;
}


.archive-single__full-col {
    width: 100%;
    padding: 0 15px;
}

.archive-single__half-col {
    width: 50%;
    padding: 0 15px;
}

.archive-single__one-third-col {
    width: 33.333%;
    padding: 0 15px;
}

.archive-single__two-third-col {
    width: 66.666%;
    padding: 0 15px;
}

.archive-single__poster {
   position: relative;
   max-width: 500px;
}

.archive-single__poster  {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
}

.archive-single__poster:after {
    content: '';
    display: block;
    padding-bottom: 146.6%;
}

.archive-single .archive-single__details-col h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.archive-single h3 {
    margin-bottom: 12px;
}


.archive-single .description {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray);
}

.archive-single .detail {
    display: block;
    font-size: 16px;
    color: var(--gray);
}

.archive-single .archive-single__details-col .detail {
    margin-bottom: 20px;
}

.archive-single__score {
    display: inline-flex;
    align-items: center;
    color: var(--secondary);
    margin-bottom: 20px;
}

.archive-single__score span {
    display: inline-block;
    margin-left: 12px;
}

.archive-single__score svg {
    width: 18x;
    height: 18px;
    margin-right: 10px;
}

.archive-single.show .archive-single__score {
    color: var(--tertiary);
}   

.info-table {
    padding: 12px 0;
    border-top: 1px solid var(--layer-color);
    border-bottom: 1px solid var(--layer-color);
    margin-bottom: 32px;    
}

.info-table > div {
    display: flex;
}

.info-table > div > h3 {
    margin-bottom: 0;
}

.info-table > div > h3,
.info-table > div > span {
    min-width: 50%;
    line-height: 1.4;
}

.info-table div a {
    display: table;
    margin: auto;
}

.info-table .created-by span:not(:last-child):after {
    content: ', ';
}

.info-table div:not(:last-child) {
    border-bottom: 1px solid var(--layer-color);
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.providers > div {
    margin-bottom: 20px;
}

.providers > div > img {
    border-radius: 8px;
    width: 32px;
    height: 32px;
}

.providers img:not(:last-child) {
    margin-right: 12px;
}

.providers .disclaimer {
    color: var(--gray);
    font-style: italic;
    font-size: 12px;
}

.trailer .trailer__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 32px;
}

.trailer .trailer__wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cast, .related {
    position: relative;
    margin-bottom: 32px;
}

.cast.loading, .related.loading {
    min-height: 200px;
}

.cast .loader,
.related .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
    .archive-single__two-third-col {
        width: 65%;
    }

    .archive-single__one-third-col  {
        width: 35%;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .archive-single__two-third-col {
        width: 60%;
    }

    .archive-single__one-third-col {
        width: 40%;
    }

}

@media screen and (max-width: 767px) {
    .archive-single {
        padding: 40px 0;
    }

    .archive-single__one-third-col {
        width: 100%;
    }

    .archive-single__two-third-col {
        width: 100%;
    }

    .archive-single__one-third-col.order-2-mobile {
        order: 2;
    }

    .archive-single__two-third-col.order-1-mobile {
        order: 1;
    }

    .archive-single__poster-col  {
        margin-bottom: 40px;
    }

    .archive-single__poster {
        width: 85%;
        margin: 0 auto;
    }
}
