.archive__items-list {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .archive__items-list {
        margin: 0 -7.5px;
    }
}

@media screen and (max-width: 360px) {
    .archive__items-list {
       margin: 0;
    }
}
