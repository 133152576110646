.archive-card {
    position: relative;
    z-index: 3;
    display: block;
    margin: 0 15px 32px;
    width: calc(25% - 30px);
    border-radius: 8px;
    overflow: hidden;
    transition: all .3s ease;
}

.archive-card:after {
    content: '';
    display: block;
    padding-bottom: 146.36%;
}

.archive-card:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(7,13,45,0.75) 80%);
    transition: opacity .4s ease;
}

.archive-card .archive-card__poster {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 16px;
    pointer-events: none;
}

.archive-card .archive-card__content {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 24px;
    opacity: 1;
    transition: opacity .4s ease;
}

.archive-card .archive-card__content h3 {
    font-size: 14px;
    margin-bottom: 4px;
    color: var(--white);
}

.archive-card .archive-card__content span {
    display: block;
    color: var(--white);
    font-size: 14px;
    margin-bottom: 12px;
}

.archive-card .archive-card__score {
    color: var(--secondary);
    margin: 0 -2.5px;
}

.archive-card .archive-card__score svg {
    width: 18px;
    height: 18px;
    margin: 0 2.5px;
}

.archive-card.show .archive-card__score {
    color: var(--tertiary);
}

.archive-card.slider-card {
    margin: 0;
    width: 100%;
}

.archive-card.slider-card:hover {
    transform: none;
}

.archive-card.roulette-card {
    width: calc(33.333% - 15px);
    position: absolute;
    top: 0;
    left: calc(50% - 15px);
    transform: translateX(-50%);
    z-index: 2;
}

.archive-card.roulette-card:hover {
    transform: translateX(-50%);
}


@media screen and (max-width: 360px) {
    .archive-card {
        width: 100%;
        margin: 0 0 32px;
    }
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .archive-card {
        margin: 0 -7.5px;
    }
}

@media screen and (max-width: 768px) {
    .archive-card .archive-card__content {
        padding: 16px;
    }
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .archive-card {
        width: calc(50% - 15px);
        margin: 0 7.5px 32px;
    }
}

@media screen and (min-width: 500px) and (max-width: 640px) {
    .archive-card {
        width: calc(50% - 30px);
        margin: 0 15px 32px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
    .archive .archive__content .archive__sidebar {
        width: 30%;
    }

    .archive .archive__content .archive__list-wrapper {
        width: 70%;
    }
}

@media screen and (min-width: 641px) and (max-width: 1279px) {
    .archive-card {
        width: calc(33.333% - 30px);
        margin: 0 15px 32px;
    }
}

@media screen and (min-width: 1024px) {
    
    .archive-card:hover {
        transform: scale(1.05);
    }
    
    .archive-card:hover:before {
        opacity: 0;
    }

    .archive-card:hover .archive-card__content {
        opacity: 0;
    }

    .archive-card.slider-card.dragging {
        transform: scale(0.9);
        pointer-events: none;
    }
    
    .archive-card.slider-card.dragging {
        transform: scale(0.9);
    }
    
    .archive-card.slider-card.dragging .archive-card__content {
        opacity: 0;
    }
    
}

@media screen and (max-width: 767px) {
    .archive-card.roulette-card {
        width: calc(50% - 7.5px);
        top: 0;
        left: 0;
        transform: none;
    }

    .archive-card.roulette-card:hover {
        transform: none;
    }
}