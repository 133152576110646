.hp-head {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    padding-top: 72.9px;
    margin-top: -72.9px;
}

.hp-head .main-container {
    flex: 1;
}

.hp-head h2 {
   margin-bottom: 48px;
   text-transform: none;
   letter-spacing: normal;
   text-align: center;
   font-size: 16px;
   line-height: 1.5;
}

.hp-head h2 span {
    display: block;
}

.hp-head:after,
.hp-head:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hp-head:after {
    z-index: -1;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.45) 0%, rgba(7,13,45,1) 100%);
}

.hp-head:before {
    z-index: -2;
    background-image: url('../../img/home-bg.jpg');
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hp-head__content {
    margin: auto;
    max-width: 85%;
}

.hp-head__content .searchbar {
   margin-bottom: 24px;
}

.hp-head__content .searchbar input {
    font-size: 16px;
}

.hp-head__content .film-tv__switch {
    transform: scale(1.1);
    margin: 0 auto;
}

.hp-carousel-section {
    position: relative;
    min-height: 364px;
    margin-bottom: 60px;
}

.hp-carousel-section:first-child {
    margin-top: 60px;
}

.hp-carousel-section .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%,-50%);
}

.hp-carousel-section .hp-carousel-section__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hp-carousel-section .hp-carousel-section__head  h2 {
    margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
    .hp-head__content {
        max-width: 75%;
    }
    
    .hp-head h2 {
        font-size: 18px;
    }
}

@media screen and (min-width: 1024px) {
    .hp-head h2 {
        font-size: 22px;
    }

    .hp-head__content {
        max-width: 65%;
    }
}

.watch-roulette {
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url('../../img/roulette.jpg') center;
    background-size: cover;
    margin-bottom: 40px;
}

.watch-roulette:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.1) 0%, rgba(7,13,45,1) 100%);
}

.watch-roulette:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(0deg, rgba(7,13,45,0.1) 0%, rgba(7,13,45,1) 100%);
}

.watch-roulette .watch-roulette__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    flex: 1;
    flex-direction: row;
    align-items: center;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col {
    flex: 1;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col:last-child {
    flex: 1.5;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col > h3 {
    margin-bottom: 20px;
    line-height: 1.8;
}

.watch-roulette .watch-roulette__wrapper .watch-roulette-col > button {
    min-width: 150px;
}

.roulette {
    display: flex;
    width: 80%;
    margin: auto;
    padding: 0 7.5px;
}

.roulette .roulette-container {
    flex: 1;
    position: relative;
    overflow: hidden;
    display: flex;
    margin: 0 -15px;
}

.roulette .roulette-container .row {
    display: flex;
    flex: 1;
    min-width: 100%;
}

.roulette .roulette-container.spin .row {
    animation: scroll 2s ease infinite; 
}

.roulette .roulette-container .row .card {
    flex: 1;
    border-radius: 8px;
    background: var(--dark-primary);
    margin: 0 7.5px;
}

.roulette .roulette-container .row .card:after {
    content: '';
    display: block;
    padding-bottom: 146.36%;
}

.fade {
    opacity: 0;
    transition: 0.15s ease;
}

.fade.fade-entered {
    opacity: 1;
}

@keyframes scroll {
    from {
        transform: translateX(0%);
    } to {
        transform: translateX(-100%);
    }
}

@media screen and (max-width: 1024px) {

    .watch-roulette .watch-roulette__wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
    .watch-roulette .watch-roulette__wrapper .watch-roulette-col {
        width: 100%;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
        margin: 0 auto 32px;
        text-align: center;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
       width: 70%;
    }
}


@media screen and (max-width: 767px) {
    .watch-roulette {
        padding-top: 0;
        background-position: top center;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
       width: 100%;
       text-align: left;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child button {
        width: 100%;
    }

    .roulette {
        width: 100%;
    }

    .roulette .roulette-container .row .card:last-child {
        display: none;
    }
}

