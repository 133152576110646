input[type=range] {
    background-color: transparent;
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--layer-color);
    border-radius: 32px;
    border: 0.2px solid var(--layer-color);
}

input[type=range]::-webkit-slider-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--layer-color);
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--layer-color);
    border-radius: 32px;
    border: 0.2px solid var(--layer-color);
}

input[type=range]::-moz-range-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    margin-top: -4px;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 2px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: var(--layer-color);
    border: 0.2px solid var(--layer-color);;
    border-radius: 32px;

}
input[type=range]::-ms-fill-upper {
    background: var(--layer-color);
    border: 0.2px solid var(--layer-color);;
    border-radius: 32px;
}

input[type=range]::-ms-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    margin-top: -4px;
}
input[type=range]:focus::-ms-fill-lower {
    background: var(--layer-color);
}

input[type=range]:focus::-ms-fill-upper {
    background: var(--layer-color);
}

input[type=date] {
    background-color: transparent;
    border: 1px solid var(--layer-color);
    color: var(--white);
    padding: 8px;
    border-radius: 8px;
    outline: none;
}

input[type=date]:focus {
    border-color: var(--white);
}

input[type=date]::-webkit-calendar-picker-indicator {
    filter: invert(1);
}