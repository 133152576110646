@import url(https://fonts.googleapis.com/css2?family=Work+Sans:wght@400;500;600;700&display=swap);
body {
  margin: 0;
  font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen',
    'Ubuntu', 'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue',
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

input[type=range] {
    background-color: transparent;
    -webkit-appearance: none;
    width: 100%;
}

input[type=range]:focus {
    outline: none;
}

input[type=range]::-webkit-slider-runnable-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--layer-color);
    border-radius: 32px;
    border: 0.2px solid var(--layer-color);
}

input[type=range]::-webkit-slider-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    -webkit-appearance: none;
    margin-top: -4px;
}

input[type=range]:focus::-webkit-slider-runnable-track {
    background: var(--layer-color);
}

input[type=range]::-moz-range-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: var(--layer-color);
    border-radius: 32px;
    border: 0.2px solid var(--layer-color);
}

input[type=range]::-moz-range-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    margin-top: -4px;
}

input[type=range]::-ms-track {
    width: 100%;
    height: 4px;
    cursor: pointer;
    background: transparent;
    border-color: transparent;
    border-width: 2px 0;
    color: transparent;
}

input[type=range]::-ms-fill-lower {
    background: var(--layer-color);
    border: 0.2px solid var(--layer-color);;
    border-radius: 32px;

}
input[type=range]::-ms-fill-upper {
    background: var(--layer-color);
    border: 0.2px solid var(--layer-color);;
    border-radius: 32px;
}

input[type=range]::-ms-thumb {
    border: 1px solid var(--cta);
    height: 10px;
    width: 10px;
    border-radius: 50%;
    background: var(--cta);
    cursor: pointer;
    margin-top: -4px;
}
input[type=range]:focus::-ms-fill-lower {
    background: var(--layer-color);
}

input[type=range]:focus::-ms-fill-upper {
    background: var(--layer-color);
}

input[type=date] {
    background-color: transparent;
    border: 1px solid var(--layer-color);
    color: var(--white);
    padding: 8px;
    border-radius: 8px;
    outline: none;
}

input[type=date]:focus {
    border-color: var(--white);
}

input[type=date]::-webkit-calendar-picker-indicator {
    -webkit-filter: invert(1);
            filter: invert(1);
}
.slick-slider .slick-slide > div {
    margin: 0 15px;
}

.slick-slider .slick-list {
    margin: 0 -15px;
}

.slider-custom-controls {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.slider-custom-controls .custom-slider-button {
    background-color: var(--cta);
    color: var(--white);
    border-radius: 8px;
    line-height: 0px;
    padding: 4px 8px;
    text-align: center;
}

.slider-custom-controls .custom-slider-button:first-child {
   margin-right: 12px;
}

.slider-custom-controls .custom-slider-button svg {
    width: 16px;
    height: 16px;
}

@media screen and (min-width: 1025px) {
    .slider-custom-controls .custom-slider-button:hover {
        opacity: 0.75;
    }   
}
:root {
	--primary: #070d2d;
	--dark-primary: #05081a;
	--dark-primary-layer: rgba(5,8,26, 0.95);
	--secondary: #FF1053;
	--tertiary: #7A28CB;
	--cta: #449DD1;
	--cta-hover: #257db1;
	--black: #000000;
	--light-black: #080808;
	--white: #ffffff;
	--layer-color: rgba(255, 255, 255, 0.1);
	--gray: #b2b2b2;
	--search-bar: rgba(1,2,10, 0.6);

}

body {
	margin: 0;
	padding: 0;
	background-color: #070d2d;
	background-color: var(--primary);
	color: #ffffff;
	color: var(--white);
	font-family: 'Work Sans', sans-serif;
}

html.no-scroll {
	overflow: hidden;
}

* {
	box-sizing: border-box;
}

h1, h2, h3, h4 {
	font-weight: 500;
	letter-spacing: 1.2px;
	line-height: 1.3;
	margin: 0;
}

h1 {
	font-size: 24px;
	text-transform: uppercase;
}

h2 {
	font-size: 18px;
	text-transform: uppercase;
}

h3 {
	font-size: 14px;
	line-height: 1.5;
	text-transform: uppercase;
}

h4 {
	font-size: 12px;
	text-transform: uppercase;
}

p  {
	margin-top: 0;
}

a {
	color: #449DD1;
	color: var(--cta);
	text-decoration: none;
}

.logo {
	position: relative;
	z-index: 91;
}

.logo img {
	width: 155px;
}

.main-container {
	width: 100%;
	padding: 0 24px;
	max-width: 1280px;
	margin: 0 auto;
}

.loader {
	margin: auto;
	text-align: center;
}

.off-canvas-wrapper {
	transition: -webkit-transform .4s ease;
	transition: transform .4s ease;
	transition: transform .4s ease, -webkit-transform .4s ease;
	min-height: 100vh;
}

.off-canvas-wrapper.nav-displayed {
	-webkit-transform: translateX(-21vw);
	        transform: translateX(-21vw);
}

.off-canvas-wrapper .off-canvas-wrapper__backdrop {
	position: fixed;
	top: 0;
	left: 0;
	height: 100%;
	width: 100%;
	z-index: 90;
	background-color: #05081a;
	background-color: var(--dark-primary);
	opacity: 0;
	visibility: visible;
	-webkit-transform: translateY(-100%);
	        transform: translateY(-100%);
	transition: opacity .4s ease, visibility 0s .4s;
}

.off-canvas-wrapper.nav-displayed .off-canvas-wrapper__backdrop {
	visibility: visible;
	-webkit-transform: translateY(0%);
	        transform: translateY(0%);
	opacity: 0.8;
	transition: opacity .4s ease;
}

@-webkit-keyframes loaderBounce {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

@keyframes loaderBounce {
	0% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
	50% {
		-webkit-transform: translateY(10px);
		        transform: translateY(10px);
	}
	100% {
		-webkit-transform: translateY(0);
		        transform: translateY(0);
	}
}

.loader span {
	display: inline-block;
	height: 12px;
	width: 12px;
	border-radius: 50%;
	background-color: #FF1053;
	background-color: var(--secondary);
	margin: 0 5px;
	-webkit-animation: loaderBounce .8s ease-in-out infinite;
	        animation: loaderBounce .8s ease-in-out infinite;
}

.loader span:nth-child(2) {
	-webkit-animation-delay: .1s;
	        animation-delay: .1s;
}

.loader span:nth-child(3) {
	-webkit-animation-delay: .2s;
	        animation-delay: .2s;
}

button {
	color: #ffffff;
	color: var(--white);
	background-color: #449DD1;
	background-color: var(--cta);
	padding: 6px 16px;
	border-radius: 6px;
	font-weight: 500;
	letter-spacing: 1.2px;
	text-align: center;
	font-size: 14px;
	border: none;
	outline: none;
	cursor: pointer;
	transition: background-color .25s ease;
}

button:hover {
	background-color: #257db1;
	background-color: var(--cta-hover);
}

.film-tv__switch {
    position: relative;
    z-index: 2;
    display: block;
	width: 120px;
    margin-bottom: 16px;
    border: 1.5px solid #FF1053;
    border: 1.5px solid var(--secondary);
    border-radius: 16px;
    transition: border-color .3s;
}

.film-tv__switch:after {
    content: '';
    position: absolute;
    z-index: -1;
    top: -1.5px;
    left: -1.5px;
    height: calc(100% + 3px);
    transition: all .3s;
    background-color: #FF1053;
    background-color: var(--secondary);
    width: 46.5px;
    border-radius: 16px;
}

.film-tv__switch.shows {
    border-color: #7A28CB;
    border-color: var(--tertiary);
}

.film-tv__switch.shows:after {
    left: calc(100% - 70.5px);
    background-color: #7A28CB;
    background-color: var(--tertiary);
    width: 72.5px;
}

.film-tv__switch span {
    display: inline-block;
    font-size: 12.5px;
    padding: 4px 8px;
    cursor: pointer;
}

.film-tv__switch span:first-child {
    max-width: 46px;
}

.film-tv__switch span:last-child {
    max-width: 72px;
}

.searchbar {
	display: flex;
	width: 100%;
}

.searchbar button {
	padding-left: 8px 12.5px;
	background-color: rgba(1,2,10, 0.6);
	background-color: var(--search-bar);
	border-top-left-radius: 0;
	border-bottom-left-radius: 0;
	border-top-right-radius: 16px;
	border-bottom-right-radius: 16px;
	font-size: 16px;
	line-height: 16px;
}

.searchbar input {
	flex: 2 1;
	padding: 8px 0 8px 12.5px;
	border-top-left-radius: 16px;
	border-bottom-left-radius: 16px;
	border: none;
	outline: none;
	background-color: rgba(1,2,10, 0.6);
	background-color: var(--search-bar);
	color: #ffffff;
	color: var(--white);
	font-size: 14px;
}

@media screen and (min-width: 1328px) {
	.main-container {
		padding: 0;
	}
}

@media screen and (max-width: 640px) {
	.off-canvas-wrapper.nav-displayed {
		-webkit-transform: translateX(-100vw);
		        transform: translateX(-100vw);
	}
	.search-bar {
		max-width: none;
	}
}

@media screen and (min-width: 641px) and (max-width: 1023px ) {
	.off-canvas-wrapper.nav-displayed {
		-webkit-transform: translateX(-40vw);
		        transform: translateX(-40vw);
	}
	.search-bar {
		max-width: 50%;
	}
}

@media screen and (min-width: 1024px) and (max-width: 1279px ) {
	.off-canvas-wrapper.nav-displayed {
		-webkit-transform: translateX(-30vw);
		        transform: translateX(-30vw);
	}
}
.MainHeader_main-header__rRVgZ {
    position: relative;
    z-index: 99;
    padding: 14px 24px;
    display: flex;
    align-items: center;
    justify-content: space-between;
}

nav {
    position: fixed;
    top: 0;
    right: 0;
    height: 100%;
    width: 21vw;
    z-index: 90;
    padding-top: 100px;
    background-color: var(--dark-primary);
    -webkit-transform: translateX(100%);
            transform: translateX(100%);
}

.MainHeader_close__1fSSJ {
    position: absolute;
    top: 14px;
    right: 24px;
    height: 30px;
    width: 30px;
    cursor: pointer;
}

.MainHeader_close__1fSSJ svg{
    height: 30px;
    width: 30px;
}

nav ul {
    margin: 0;
    padding: 0;
    list-style: none;
}

nav ul.MainHeader_menu__3eyti > li {
    padding: 0 24px;
    margin-bottom: 28px;
}

nav ul li > span:hover {
    opacity: 0.75;
}

nav ul.MainHeader_menu__3eyti > li > a,
nav ul.MainHeader_menu__3eyti > li > span {
    font-size: 16px;
    color: var(--white);
    letter-spacing: 1.2px;
}

nav ul.MainHeader_menu__3eyti > li > a {
    display: table;
    position: relative;
    margin-left: 16px;
    padding-bottom: 4px;
}

nav ul.MainHeader_menu__3eyti > li > span {
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background-color: var(--layer-color);
    padding: 10px 16px;
    border-radius: 8px;
}

nav ul li .MainHeader_submenu__2Vu84 {
    max-height: 0;
    overflow: hidden;
    transition: max-height .4s ease-in-out;
}

nav ul li .MainHeader_submenu__2Vu84 li {
    padding-bottom: 8px;
}

nav ul li .MainHeader_submenu__2Vu84 li:first-child {
    padding-top: 16px;
}

nav ul li .MainHeader_submenu__2Vu84 li:last-child {
    border-bottom: 1px solid var(--layer-color);
}

nav ul li .MainHeader_submenu__2Vu84 li a {
    position: relative;
    display: table;
    font-size: 14px;
    letter-spacing: 1.2px;
    margin: 0 0 4px 16px;
    padding-bottom: 4px;
    color: var(--white);
}

nav ul.MainHeader_menu__3eyti > li > a:hover,
nav ul > li > .MainHeader_submenu__2Vu84 > li > a:hover {
    opacity: 0.75;
}


.MainHeader_active-link__G2sM7 {
    opacity: 0.5;
}


nav ul li .MainHeader_submenu__2Vu84.MainHeader_active__3UWoh {
    max-height: 600px;
}

.MainHeader_hamburger__25zhY {
    width: 30px;
    height: 30px;
    cursor: pointer;
}

.MainHeader_hamburger__25zhY svg {
    width: 30px;
    height: 30px;
}

/* Responsive */

@media screen and (max-width: 640px) {
    nav {
        width: 100vw;
    }
    .MainHeader_close__1fSSJ {
        right: 0;
        left: 24px;
        -webkit-transform: scaleX(-1);
                transform: scaleX(-1);
    }
}

@media screen and (min-width: 641px) and (max-width: 1023px ) {
	nav {
        width: 40vw;
    }
}

@media screen and (min-width: 1024px) and (max-width: 1200px ) {
	nav {
        width: 30vw;
    }
}
.ArchiveCard_archive-card__2Imc5 {
    position: relative;
    z-index: 3;
    display: block;
    margin: 0 15px 32px;
    width: calc(25% - 30px);
    border-radius: 8px;
    overflow: hidden;
    transition: all .3s ease;
}

.ArchiveCard_archive-card__2Imc5:after {
    content: '';
    display: block;
    padding-bottom: 146.36%;
}

.ArchiveCard_archive-card__2Imc5:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(7,13,45,0.75) 80%);
    transition: opacity .4s ease;
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__poster__1FMpU {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    margin-bottom: 16px;
    pointer-events: none;
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__content__1J2Dr {
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    padding: 16px 24px;
    opacity: 1;
    transition: opacity .4s ease;
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__content__1J2Dr h3 {
    font-size: 14px;
    margin-bottom: 4px;
    color: var(--white);
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__content__1J2Dr span {
    display: block;
    color: var(--white);
    font-size: 14px;
    margin-bottom: 12px;
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__score__2gCpn {
    color: var(--secondary);
    margin: 0 -2.5px;
}

.ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__score__2gCpn svg {
    width: 18px;
    height: 18px;
    margin: 0 2.5px;
}

.ArchiveCard_archive-card__2Imc5.ArchiveCard_show__13aUN .ArchiveCard_archive-card__score__2gCpn {
    color: var(--tertiary);
}

.ArchiveCard_archive-card__2Imc5.ArchiveCard_slider-card__2FFt7 {
    margin: 0;
    width: 100%;
}

.ArchiveCard_archive-card__2Imc5.ArchiveCard_slider-card__2FFt7:hover {
    -webkit-transform: none;
            transform: none;
}

.ArchiveCard_archive-card__2Imc5.ArchiveCard_roulette-card__125Ed {
    width: calc(33.333% - 15px);
    position: absolute;
    top: 0;
    left: calc(50% - 15px);
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
    z-index: 2;
}

.ArchiveCard_archive-card__2Imc5.ArchiveCard_roulette-card__125Ed:hover {
    -webkit-transform: translateX(-50%);
            transform: translateX(-50%);
}


@media screen and (max-width: 360px) {
    .ArchiveCard_archive-card__2Imc5 {
        width: 100%;
        margin: 0 0 32px;
    }
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .ArchiveCard_archive-card__2Imc5 {
        margin: 0 -7.5px;
    }
}

@media screen and (max-width: 768px) {
    .ArchiveCard_archive-card__2Imc5 .ArchiveCard_archive-card__content__1J2Dr {
        padding: 16px;
    }
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .ArchiveCard_archive-card__2Imc5 {
        width: calc(50% - 15px);
        margin: 0 7.5px 32px;
    }
}

@media screen and (min-width: 500px) and (max-width: 640px) {
    .ArchiveCard_archive-card__2Imc5 {
        width: calc(50% - 30px);
        margin: 0 15px 32px;
    }
}

@media screen and (min-width: 769px) and (max-width: 1279px) {
    .ArchiveCard_archive__2jNtU .ArchiveCard_archive__content__2KF8v .ArchiveCard_archive__sidebar__C_bLM {
        width: 30%;
    }

    .ArchiveCard_archive__2jNtU .ArchiveCard_archive__content__2KF8v .ArchiveCard_archive__list-wrapper__2py1t {
        width: 70%;
    }
}

@media screen and (min-width: 641px) and (max-width: 1279px) {
    .ArchiveCard_archive-card__2Imc5 {
        width: calc(33.333% - 30px);
        margin: 0 15px 32px;
    }
}

@media screen and (min-width: 1024px) {
    
    .ArchiveCard_archive-card__2Imc5:hover {
        -webkit-transform: scale(1.05);
                transform: scale(1.05);
    }
    
    .ArchiveCard_archive-card__2Imc5:hover:before {
        opacity: 0;
    }

    .ArchiveCard_archive-card__2Imc5:hover .ArchiveCard_archive-card__content__1J2Dr {
        opacity: 0;
    }

    .ArchiveCard_archive-card__2Imc5.ArchiveCard_slider-card__2FFt7.ArchiveCard_dragging__krqbs {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
        pointer-events: none;
    }
    
    .ArchiveCard_archive-card__2Imc5.ArchiveCard_slider-card__2FFt7.ArchiveCard_dragging__krqbs {
        -webkit-transform: scale(0.9);
                transform: scale(0.9);
    }
    
    .ArchiveCard_archive-card__2Imc5.ArchiveCard_slider-card__2FFt7.ArchiveCard_dragging__krqbs .ArchiveCard_archive-card__content__1J2Dr {
        opacity: 0;
    }
    
}

@media screen and (max-width: 767px) {
    .ArchiveCard_archive-card__2Imc5.ArchiveCard_roulette-card__125Ed {
        width: calc(50% - 7.5px);
        top: 0;
        left: 0;
        -webkit-transform: none;
                transform: none;
    }

    .ArchiveCard_archive-card__2Imc5.ArchiveCard_roulette-card__125Ed:hover {
        -webkit-transform: none;
                transform: none;
    }
}
.hp-head {
    position: relative;
    z-index: 3;
    display: flex;
    align-items: center;
    justify-content: center;
    min-height: 70vh;
    padding-top: 72.9px;
    margin-top: -72.9px;
}

.hp-head .main-container {
    flex: 1 1;
}

.hp-head h2 {
   margin-bottom: 48px;
   text-transform: none;
   letter-spacing: normal;
   text-align: center;
   font-size: 16px;
   line-height: 1.5;
}

.hp-head h2 span {
    display: block;
}

.hp-head:after,
.hp-head:before {
    content: '';
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.hp-head:after {
    z-index: -1;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.45) 0%, rgba(7,13,45,1) 100%);
}

.hp-head:before {
    z-index: -2;
    background-image: url(/static/media/home-bg.c4c32539.jpg);
    background-size: cover;
    background-position: center;
    background-repeat: no-repeat;
}

.hp-head__content {
    margin: auto;
    max-width: 85%;
}

.hp-head__content .searchbar {
   margin-bottom: 24px;
}

.hp-head__content .searchbar input {
    font-size: 16px;
}

.hp-head__content .film-tv__switch {
    -webkit-transform: scale(1.1);
            transform: scale(1.1);
    margin: 0 auto;
}

.hp-carousel-section {
    position: relative;
    min-height: 364px;
    margin-bottom: 60px;
}

.hp-carousel-section:first-child {
    margin-top: 60px;
}

.hp-carousel-section .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%,-50%);
            transform: translate(-50%,-50%);
}

.hp-carousel-section .hp-carousel-section__head {
    display: flex;
    justify-content: space-between;
    align-items: center;
}

.hp-carousel-section .hp-carousel-section__head  h2 {
    margin-bottom: 16px;
}

@media screen and (min-width: 768px) {
    .hp-head__content {
        max-width: 75%;
    }
    
    .hp-head h2 {
        font-size: 18px;
    }
}

@media screen and (min-width: 1024px) {
    .hp-head h2 {
        font-size: 22px;
    }

    .hp-head__content {
        max-width: 65%;
    }
}

.watch-roulette {
    position: relative;
    min-height: 400px;
    display: flex;
    align-items: center;
    justify-content: center;
    background: url(/static/media/roulette.c2c72aff.jpg) center;
    background-size: cover;
    margin-bottom: 40px;
}

.watch-roulette:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.1) 0%, rgba(7,13,45,1) 100%);
}

.watch-roulette:before {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(0deg, rgba(7,13,45,0.1) 0%, rgba(7,13,45,1) 100%);
}

.watch-roulette .watch-roulette__wrapper {
    position: relative;
    z-index: 3;
    display: flex;
    flex: 1 1;
    flex-direction: row;
    align-items: center;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col {
    flex: 1 1;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col:last-child {
    flex: 1.5 1;
}


.watch-roulette .watch-roulette__wrapper .watch-roulette-col > h3 {
    margin-bottom: 20px;
    line-height: 1.8;
}

.watch-roulette .watch-roulette__wrapper .watch-roulette-col > button {
    min-width: 150px;
}

.roulette {
    display: flex;
    width: 80%;
    margin: auto;
    padding: 0 7.5px;
}

.roulette .roulette-container {
    flex: 1 1;
    position: relative;
    overflow: hidden;
    display: flex;
    margin: 0 -15px;
}

.roulette .roulette-container .row {
    display: flex;
    flex: 1 1;
    min-width: 100%;
}

.roulette .roulette-container.spin .row {
    -webkit-animation: scroll 2s ease infinite;
            animation: scroll 2s ease infinite; 
}

.roulette .roulette-container .row .card {
    flex: 1 1;
    border-radius: 8px;
    background: var(--dark-primary);
    margin: 0 7.5px;
}

.roulette .roulette-container .row .card:after {
    content: '';
    display: block;
    padding-bottom: 146.36%;
}

.fade {
    opacity: 0;
    transition: 0.15s ease;
}

.fade.fade-entered {
    opacity: 1;
}

@-webkit-keyframes scroll {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    } to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@keyframes scroll {
    from {
        -webkit-transform: translateX(0%);
                transform: translateX(0%);
    } to {
        -webkit-transform: translateX(-100%);
                transform: translateX(-100%);
    }
}

@media screen and (max-width: 1024px) {

    .watch-roulette .watch-roulette__wrapper {
        flex-direction: column;
        align-items: flex-start;
    }
    .watch-roulette .watch-roulette__wrapper .watch-roulette-col {
        width: 100%;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
        margin: 0 auto 32px;
        text-align: center;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
       width: 70%;
    }
}


@media screen and (max-width: 767px) {
    .watch-roulette {
        padding-top: 0;
        background-position: top center;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child {
       width: 100%;
       text-align: left;
    }

    .watch-roulette .watch-roulette__wrapper .watch-roulette-col:first-child button {
        width: 100%;
    }

    .roulette {
        width: 100%;
    }

    .roulette .roulette-container .row .card:last-child {
        display: none;
    }
}


.ArchiveFilter_archive-filter__3YbSz {
    background-color: var(--dark-primary);
    border-radius: 16px;
}

.ArchiveFilter_archive-filter__head__11OlR {
    display: flex;
    align-items: center;
    justify-content: space-between;
    padding: 16px 22px;
    margin-bottom: 8px;
}

.ArchiveFilter_archive-filter__body__DNvJV {
    padding: 0 22px 16px;
}

.ArchiveFilter_archive-filter__3YbSz h4 {
    margin: 0 0 16px;
}

.ArchiveFilter_archive-filter__3YbSz .ArchiveFilter_archive-filter__body__DNvJV .ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG {
    padding-bottom: 16px;
    margin-bottom: 16px;
    border-bottom: 1px solid var(--layer-color);
}

.ArchiveFilter_archive-filter__3YbSz .ArchiveFilter_archive-filter__body__DNvJV .ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG:nth-last-child(2) {
    border-bottom: none;
    margin-bottom: 0;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_form-section__head__1ug_S {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 16px;
    cursor: pointer;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_form-section__head__1ug_S h4 {
    margin: 0;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_form-section__body__PhmET {
   max-height: 0;
   overflow: hidden;
   transition: max-height .4s ease;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG.ArchiveFilter_open-accordion__1JSz6 .ArchiveFilter_form-section__body__PhmET {
    max-height: 600px;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_genre-tag__2jMeN {
    display: inline-block;
    margin-right: 8px;
    margin-bottom: 12px;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_genre-tag__2jMeN label{
   font-size: 14px;
   display: inline-block;
   cursor: pointer;
   padding: 2px 8px;
   border: 2px solid var(--layer-color);
   border-radius: 16px;
   transition: background-color .2s ease;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_genre-tag__2jMeN input{
    position: absolute;
    opacity: 0;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_genre-tag__2jMeN input:checked ~ label{
   background-color: var(--layer-color);
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_rating-selector__3lQSc input {
    display: block;
    width: 100%;
    max-width: 150px;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_rating-selector__3lQSc .ArchiveFilter_rating-selector__rating-displayer__2rzRA {
    margin-top: 16px;
    color: var(--secondary);
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_rating-selector__3lQSc .ArchiveFilter_rating-selector__rating-displayer__2rzRA.ArchiveFilter_tv-shows__BoIOE {
    color: var(--tertiary);
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_rating-selector__3lQSc .ArchiveFilter_rating-selector__rating-displayer__2rzRA svg {
    width: 22px;
    height: 22px;
    margin: 0 4px;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_date-picker__2rO12 div {
    display: flex;
    align-items: center;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_date-picker__2rO12 div svg {
    margin-right: 10px;
}

.ArchiveFilter_archive-filter__form__3mzYR .ArchiveFilter_archive-filter__form-section__1fuDG .ArchiveFilter_date-picker__2rO12 div:first-of-type  {
    margin-bottom: 8px;
}

.ArchiveFilter_archive-filter__form__3mzYR button {
    display: block;
    width: 100%;
}

.ArchiveFilter_archive-filter__body-close__2TQJj {
    display: none;
}

@media screen and (min-width: 640px) and (max-width: 1024px) { 
    .ArchiveFilter_archive-filter__3YbSz {
        max-width: 50%;
    } 
}

@media screen and (max-width: 1024px) {
    .ArchiveFilter_archive-filter__head__11OlR {
        cursor: pointer;
        padding: 10px 16px;
        margin-bottom: 0;
    } 

    .ArchiveFilter_archive-filter__body__DNvJV {
        position: fixed;
        bottom: 0;
        left: 0;
        width: 100%;
        padding-top: 24px;
        border-top-left-radius: 16px;
        border-top-right-radius: 16px;
        z-index: 99;
        background-color: var(--dark-primary-layer);
        -webkit-transform: translateY(100%);
                transform: translateY(100%);
        transition: -webkit-transform .4s;
        transition: transform .4s;
        transition: transform .4s, -webkit-transform .4s;
    } 

    .ArchiveFilter_archive-filter__3YbSz.ArchiveFilter_open__2cHZd .ArchiveFilter_archive-filter__body__DNvJV {
        -webkit-transform: translateY(0%);
                transform: translateY(0%);
    } 

    .ArchiveFilter_archive-filter__body-close__2TQJj {
        position: absolute;
        z-index: 99;
        top: 16px;
        right: 24px;
        display: block;
        cursor: pointer;
        width: 24px;
        height: 24px;
        text-align: center;
        line-height: 22px;
        border-radius: 50%;
        background-color: var(--layer-color);
        color: var(--white);
    } 

    .ArchiveFilter_archive-filter__body-close__2TQJj svg {
        vertical-align: middle;
        width: 18px;
        height: 18px;
    }
}
.ArchiveItemsList_archive__items-list__2hxpk {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

@media screen and (min-width: 361px) and (max-width: 499px) {
    .ArchiveItemsList_archive__items-list__2hxpk {
        margin: 0 -7.5px;
    }
}

@media screen and (max-width: 360px) {
    .ArchiveItemsList_archive__items-list__2hxpk {
       margin: 0;
    }
}

.archive {
    z-index: 2;
    padding: 60px 0;
}

.archive .archive__content {
    display: flex;
    flex-wrap: wrap;
    padding-bottom: 48px;
    margin: 0 -15px;
}

.archive .archive__content .archive__sidebar {
    width: 25%;
    padding: 0 15px;
}

.archive .archive__content .archive__sidebar .archive__sidebar-searchbar-wrapper {
    margin-bottom: 24px;
}

.archive .archive__content .archive__sidebar .archive__sidebar-searchbar-wrapper .searchbar {
   margin: 16px 0;
}

.archive  h1 {
    font-size: 22px;
    color: var(--white);
    margin-bottom: 24px;
}

.archive .title-tag {
    display: table;
    font-size: 12.5px;
    letter-spacing: 1.2px;
    padding: 2px 6px;
    border-radius: 16px;
    font-weight: 500;
    color: var(--white);
    background-color: var(--secondary);
    margin-bottom: 8px;
}


.archive .archive__content .archive__list-wrapper {
    position: relative;
    width: 75%;
    padding: 0 15px;
    min-height: 60vh;
}

.archive .archive__content .archive__list-wrapper .loader {
    position: absolute;
    top: 50%;
    left: 0;
    width: 100%;
    -webkit-transform: translateY(-50%);
            transform: translateY(-50%);
}

.archive .archive__content .archive__list-wrapper .load-more span {
    display: block;
    width: 16px;
    height: 16px;
    background-color: var(--secondary);
    border-radius: 50%;
    margin: 10px auto;
    -webkit-animation: loaderBounce .8s ease infinite;
            animation: loaderBounce .8s ease infinite;
}


.archive.tv-shows .title-tag {
    background-color: var(--tertiary);
}

.archive.tv-shows .archive__content .archive__list-wrapper .load-more span {
    background-color: var(--tertiary);
}

.archive.tv-shows .archive__content .archive__list-wrapper .loader span {
    background-color: var(--tertiary);
}

@media screen and (min-width: 1025px) and (max-width: 1200px) {
    .archive .archive__content .archive__sidebar {
        width: 30%;
    }
    .archive .archive__content .archive__list-wrapper {
        width: 70%;
    }
}

@media screen and (max-width: 1024px) {
    .archive {
        padding: 40px 0;
    }
    .archive .archive__content .archive__sidebar {
        width: 100%;
        margin-bottom: 32px;
    }
    .archive .archive__content .archive__list-wrapper {
        width: 100%;
    }
}
.archive-single {
    position: relative;
    z-index: 2;
    padding: 60px 0;
}

.archive-single-wrapper {
    max-width: 1000px;
    margin: auto;
}

.archive-single__bg {
    position: absolute;
    z-index: -1;
    top: -75.43px;
    left: 0;
    width: 100%;
    height: 450px;
    background-position: top center;
    background-repeat: no-repeat;
    background-size: cover;
}

.archive-single__bg:before {
    content: '';
    position: absolute;
    z-index: 2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(7,13,45,0.7318277652858018) 0%, rgba(7,13,45,1) 100%);
}

.archive-single__row {
    display: flex;
    flex-wrap: wrap;
    margin: 0 -15px;
}

.archive-single__row:not(:last-child) { 
    margin-bottom: 32px;
}


.archive-single__full-col {
    width: 100%;
    padding: 0 15px;
}

.archive-single__half-col {
    width: 50%;
    padding: 0 15px;
}

.archive-single__one-third-col {
    width: 33.333%;
    padding: 0 15px;
}

.archive-single__two-third-col {
    width: 66.666%;
    padding: 0 15px;
}

.archive-single__poster {
   position: relative;
   max-width: 500px;
}

.archive-single__poster  {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    border-radius: 8px;
    overflow: hidden;
}

.archive-single__poster:after {
    content: '';
    display: block;
    padding-bottom: 146.6%;
}

.archive-single .archive-single__details-col h1 {
    text-transform: uppercase;
    margin-bottom: 20px;
}

.archive-single h3 {
    margin-bottom: 12px;
}


.archive-single .description {
    font-size: 16px;
    line-height: 1.5;
    color: var(--gray);
}

.archive-single .detail {
    display: block;
    font-size: 16px;
    color: var(--gray);
}

.archive-single .archive-single__details-col .detail {
    margin-bottom: 20px;
}

.archive-single__score {
    display: inline-flex;
    align-items: center;
    color: var(--secondary);
    margin-bottom: 20px;
}

.archive-single__score span {
    display: inline-block;
    margin-left: 12px;
}

.archive-single__score svg {
    width: 18x;
    height: 18px;
    margin-right: 10px;
}

.archive-single.show .archive-single__score {
    color: var(--tertiary);
}   

.info-table {
    padding: 12px 0;
    border-top: 1px solid var(--layer-color);
    border-bottom: 1px solid var(--layer-color);
    margin-bottom: 32px;    
}

.info-table > div {
    display: flex;
}

.info-table > div > h3 {
    margin-bottom: 0;
}

.info-table > div > h3,
.info-table > div > span {
    min-width: 50%;
    line-height: 1.4;
}

.info-table div a {
    display: table;
    margin: auto;
}

.info-table .created-by span:not(:last-child):after {
    content: ', ';
}

.info-table div:not(:last-child) {
    border-bottom: 1px solid var(--layer-color);
    padding-bottom: 12px;
    margin-bottom: 12px;
}

.providers > div {
    margin-bottom: 20px;
}

.providers > div > img {
    border-radius: 8px;
    width: 32px;
    height: 32px;
}

.providers img:not(:last-child) {
    margin-right: 12px;
}

.providers .disclaimer {
    color: var(--gray);
    font-style: italic;
    font-size: 12px;
}

.trailer .trailer__wrapper {
    position: relative;
    padding-bottom: 56.25%;
    margin-bottom: 32px;
}

.trailer .trailer__wrapper iframe {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
}

.cast, .related {
    position: relative;
    margin-bottom: 32px;
}

.cast.loading, .related.loading {
    min-height: 200px;
}

.cast .loader,
.related .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    -webkit-transform: translate(-50%, -50%);
            transform: translate(-50%, -50%);
}

@media screen and (min-width: 1025px) and (max-width: 1366px) {
    .archive-single__two-third-col {
        width: 65%;
    }

    .archive-single__one-third-col  {
        width: 35%;
    }
    
}

@media screen and (min-width: 768px) and (max-width: 1024px) {
    .archive-single__two-third-col {
        width: 60%;
    }

    .archive-single__one-third-col {
        width: 40%;
    }

}

@media screen and (max-width: 767px) {
    .archive-single {
        padding: 40px 0;
    }

    .archive-single__one-third-col {
        width: 100%;
    }

    .archive-single__two-third-col {
        width: 100%;
    }

    .archive-single__one-third-col.order-2-mobile {
        order: 2;
    }

    .archive-single__two-third-col.order-1-mobile {
        order: 1;
    }

    .archive-single__poster-col  {
        margin-bottom: 40px;
    }

    .archive-single__poster {
        width: 85%;
        margin: 0 auto;
    }
}

.ActorCard_actor-card__2PXtA {
    position: relative;
    z-index: 3;
    border-radius: 8px;
    overflow: hidden;
}

.ActorCard_actor-card__2PXtA:after {
    content: '';
    display: block;
    padding-bottom: 150%;
}

.ActorCard_actor-card__2PXtA:before {
    content: '';
    position: absolute;
    z-index: -1;
    top: 0;
    left: 0;
    height: 101%;
    width: 101%;
    background-color: rgba(17,3,46,0.5);
    background: linear-gradient(180deg, rgba(0,0,0,0) 0%, rgba(7,13,45,0.75) 70%);
    transition: opacity .4s ease;
}

.ActorCard_actor-card__2PXtA .ActorCard_actor-card__pic__1V17q {
    position: absolute;
    z-index: -2;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    background-position: center;
    background-size: cover;
    background-repeat: no-repeat;
}

.ActorCard_actor-card__2PXtA .ActorCard_actor-card__content__1vPQk {
    position: absolute;
    bottom: 16px;
    left: 0;
    width: 100%;
    padding: 0 8px;
}

.ActorCard_actor-card__2PXtA .ActorCard_actor-card__content__1vPQk .ActorCard_actor-card__name__2F-lQ {
    font-size: 12px;
    margin-bottom: 4px;
    text-align: center;
}

.ActorCard_actor-card__2PXtA .ActorCard_actor-card__content__1vPQk .ActorCard_actor-card__character__AhEo8 {
    font-size: 10px;
    color: var(--gray);
    text-align: center;
}
