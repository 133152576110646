.slick-slider .slick-slide > div {
    margin: 0 15px;
}

.slick-slider .slick-list {
    margin: 0 -15px;
}

.slider-custom-controls {
    margin-top: 18px;
    display: flex;
    justify-content: flex-end;
}

.slider-custom-controls .custom-slider-button {
    background-color: var(--cta);
    color: var(--white);
    border-radius: 8px;
    line-height: 0px;
    padding: 4px 8px;
    text-align: center;
}

.slider-custom-controls .custom-slider-button:first-child {
   margin-right: 12px;
}

.slider-custom-controls .custom-slider-button svg {
    width: 16px;
    height: 16px;
}

@media screen and (min-width: 1025px) {
    .slider-custom-controls .custom-slider-button:hover {
        opacity: 0.75;
    }   
}